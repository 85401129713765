
<template>
  <div class="date-info">
    <div v-if="showCreationDate"><b>Created:</b> {{ dateInformation.created }}</div>
    <div v-if="showLastModified"><b>Last changed:</b> {{ dateInformation.lastModified }}</div>
    <div v-if="showRange"><b>Valid:</b> {{ dateInformation.start }} - {{ dateInformation.end }}</div>
    <div v-if="showStart"><b>Begin:</b> {{ dateInformation.start }}</div>
    <div v-if="showRevenueEnd"><b>Revenue known until:</b> {{ dateInformation.lastKnownTurnoverDate }}</div>
    <div v-if="showEnd"><b>End:</b> {{ dateInformation.end }}</div>
  </div>
</template>

<script>

import UiMixin from "@/components/mixins/UiMixin.vue"

export default {
  name: 'DateInfo',
  mixins: [UiMixin],
  props: {
      item: Object
  },
  computed: {
    dateInformation() {
      return this.dateInfos(this.item)
    },
    showCreationDate() {
      return !!this.dateInformation.created
    },
    showLastModified() {
      const di = this.dateInformation
      return di.lastModified && di.lastModified !== di.created
    },
    showRange() {
      const di = this.dateInformation
      return di.start && di.end
    },
    showStart() {
      const di = this.dateInformation
      return di.start && !di.end
    },
    showEnd() {
      const di = this.dateInformation
      return !di.start && di.end
    },
    showRevenueEnd() {
      return !!this.dateInformation.lastKnownTurnoverDate
    },
  },
}
</script>

<style scoped>
.list-badge {
  position: absolute;
  /* transform: translate(-8px, -12px); */
}
</style>
